@import base
@keyframes scale
    0%
        transform: scale(1)
    30%
        transform: scale(1.4)
    100%
        transform: scale(1)

.scale
    animation: 1s  normal scale ease
.header
    background-color: $black
    font-weight: 600

    .container
        display: flex
        gap: 95px
        padding: 20px 50px 20px 64px
        color: $white
        font-weight: 600
        font-size: 18px
        line-height: 22px
        @media screen and (max-width: $tabletS)
            padding: 14px 81px 7px
            justify-content: space-between
            font-size: 25px
            line-height: 31px
        @media screen and (max-width: $mobileS)
            padding: 15px 30px 6px
            font-size: 20px
            line-height: 26px

    &__logo img
        @media screen and (max-width: $tabletS)
            width: 100px
        @media screen and (max-width: $mobileS)
            width: 89px

    a
        color: $white

    &__menu
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        @media screen and (max-width: $tabletS)
            position: absolute
            right: 0
            width: 0
            overflow: hidden
            height: 100vh
            transition: 0.3s ease-in-out
            z-index: 11
            background-color: $black
            flex-direction: column
            justify-content: flex-start
            &.active
                width: 100vw

        &-mobile
            display: none
            font-size: 16px
            line-height: 20px
            @media screen and (max-width: $tabletS)
                display: flex
                align-items: center
                gap: 18px
                .menu-burger
                    display: flex
                    flex-direction: column
                    gap: 1px
                    z-index: 13

                    .line
                        width: 15px
                        border-top: 2px solid $white

                    &.active
                        .line
                            &:first-child
                                transform: rotate(-45deg) translate(0.5px, 0px)

                            &:nth-child(2)
                                opacity: 0

                            &:nth-child(3)
                                transform: rotate(45deg) translate(-5px, -6px)
            @media screen and (max-width: $mobileS)
                font-size: 15px
                line-height: 18px

        .menu
            display: flex
            gap: 30px
            @media screen and (max-width: $tabletS)
                flex-direction: column
                align-items: center
                margin-bottom: 30px


    &__phone
        display: flex
        align-items: center
        svg
            margin-right: 5px

        @media screen and (max-width: $tabletS)
            svg
                width: 30px
                height: auto

    a
        opacity: 0.6

        &:hover
            opacity: 1