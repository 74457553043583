@import base

.first
    &__title
        display: flex
        flex-wrap: wrap
        align-items: end
        justify-content: center
        gap: 15px 10px
        font-family: "Thirsty Caps"
        font-size: 130px
        //line-height: 104px
        line-height: 100%
        color: $yellow
        line-height: 0.5

        h1
            margin: 0
            line-height: 1

        & > *
            display: inline
        //line-height: inherit

        &-lower
            font-size: 100px
            //line-height: 89px
            line-height: 0.6

            @media screen and (max-width: $tabletS)
                font-size: 50px
        //line-height: 44px

        @media screen and (max-width: $tabletS)
            font-size: 65px
        //line-height: 58px
        @media screen and (max-width: $mobileS)
            text-shadow: 3px 3px 7px #161200
            font-size: 60px
    //line-height: 53px


    .slick-dotted.slick-slider
        margin-bottom: 0

    .slide
        height: 674px
        background-position: center
        background-size: cover
        @media screen and (max-width: $tabletS)
            height: 400px
        @media screen and (max-width: $mobileS)
            height: 497px
            background-position: center
            //background-image: url("../img/backgrounds/fon1Mobile.png")
            //background-image: url("../img/backgrounds/fon1Mobile.png")
            background-color: $black-op80

        .slide-1
            &__image-top
                position: absolute
                top: 20px
                left: 0
                @media (max-width: $tabletS)
                    max-width: 202px
                    top: 13px
                    left: 33px
                    img
                        width: 100%
                        object-fit: contain
                @media (max-width: $mobileS)
                    max-width: 128px
                    top: 27px
                    left: 22px

            &__images
                height: 100%
                display: flex
                align-items: center

                *
                    height: fit-content

                &-1
                    margin-right: -130px
                    align-self: end
                    height: auto
                    @media screen and (max-width: $tabletS)
                        width: 241px
                        //padding-bottom: 50px
                        margin-right: -65px
                        margin-top: 40px
                        align-self: unset
                        img
                            width: 100%
                    @media screen and (max-width: $mobileS)
                        margin-right: -70px
                        width: 145px
                        img
                            width: 100%
                            object-fit: contain

                &-2
                    position: relative
                    padding-bottom: 50px
                    height: auto
                    @media screen and (max-width: $tabletS)
                        width: 310px
                        padding-bottom: 30px
                        img
                            width: 100%
                            object-fit: contain
                    @media screen and (max-width: $mobileS)
                        width: 200px
                        padding-bottom: 50px

                &-3
                    margin-left: -130px
                    height: auto
                    @media screen and (max-width: $tabletS)
                        width: 229px
                        margin-left: -65px
                        margin-bottom: 40px
                        img
                            width: 100%
                            object-fit: contain
                    @media screen and (max-width: $mobileS)
                        margin-left: -70px
                        margin-bottom: 40px
                        width: 140px
                        img
                            width: 100%
                            object-fit: contain

            &__title

                font-family: 'Spray Letters'
                font-size: 30px
                font-weight: 400
                line-height: 35px
                letter-spacing: 0.03em
                text-align: center
                width: 100%
                position: absolute
                bottom: 75px
                z-index: 2
                color: $yellow
                @media screen and (max-width: $tabletS)
                    font-size: 20px
                    line-height: 23px
                    bottom: 63px
                @media screen and (max-width: $mobileS)
                    font-size: 15px
                    line-height: 17px
                    bottom: 185px


        .container
            padding: 60px 34px
            height: 100%

            position: relative
            @media screen and (max-width: $mobileS)
                padding: 60px 0 106px
                height: 100%


        &-who
            background-image: url("../img/backgrounds/fon1.png")
            @media screen and (max-width: $mobileS)
                background-position: center
                //background-image: url("../img/backgrounds/fon1 Mobile.png")
                //background-image: url("../img/backgrounds/fon1 Mobile.png")
                background-color: $black-op80


            .container
                display: flex
                justify-content: center
                //display: grid
                //grid-template-columns: 1fr 1fr
                align-items: center
                @media screen and (max-width: $mobileS)
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                    align-items: center

            .slide__title
                text-align: center
                margin-top: -68px
                margin-left: 69px
                @media screen and (max-width: $tabletS)
                    margin-left: 44px
                    margin-top: -15px
                @media screen and (max-width: $mobileS)
                    margin-left: 0


            .slide__images
                position: relative
                //min-height: 560px
                //min-width: 600px
                display: grid
                @media screen and (max-width: $mobileS)
                    margin-left: 20px

                picture
                    position: relative

                    img
                        width: 100%

                .image-hello
                    //position: absolute
                    //top: 25px
                    //right: 0
                    width: 348px
                    grid-column: 3/6
                    grid-row: 1/4
                    place-self: start end
                    @media screen and (max-width: $tabletS)
                        width: 132px
                        margin-left: -41px
                    @media screen and (max-width: $mobileS)
                        display: none

                .image-flakes
                    width: 410px
                    height: auto
                    //position: absolute
                    //top: 0
                    //left: 0
                    grid-column: 1/6
                    grid-row: 1/4
                    @media screen and (max-width: $tabletS)
                        width: 217px
                    @media screen and (max-width: $mobileS)
                        width: 180px
                        grid-column: 1/5
                        grid-row: 1/4

                .image-pine
                    grid-column: 3/6
                    grid-row: 2/6
                    margin-top: -30px
                    width: 430px
                    height: auto
                    place-self: center
                    @media screen and (max-width: $tabletS)
                        width: 207px
                        margin-top: 0px
                    @media screen and (max-width: $mobileS)
                        margin-top: 15px
                        grid-column: 4/6
                        grid-row: 1/6
                        transform: rotate(30deg)

            & .btn
                display: none
                @media screen and (max-width: $mobileS)
                    display: block
                    margin-top: auto
                    position: relative
                    z-index: 10

        &-shop
            background-image: url("../img/backgrounds/fon2.png")

            & .container
                height: 100%
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                //background-position-y: -54px
                background-position-y: bottom
                background-position-x: center
                background-repeat: no-repeat
                gap: 40px
                @media screen and (max-width: $tabletS)
                    background-size: contain
                @media screen and (max-width: $mobileS)
                    background-position: 20px 70px
                    justify-content: space-between

            &__img
                max-width: 100%
                @media screen and (max-width: $mobileS)
                    margin-top: 70px


            & .h1
                font-size: 130px
                line-height: 150px
                margin-bottom: 36px
                @media screen and (max-width: $tabletS)
                    font-size: 90px
                    line-height: 110px
                    margin-bottom: 20px
                @media screen and (max-width: $mobileS)
                    font-size: 40px
                    line-height: 46px

            & .btn
                @media screen and (max-width: $mobileS)
                    background-color: $red-purple
                    color: $white


    &__dots
        z-index: 10
        bottom: 25px
        //@media screen and (max-width: $tabletS)
        @media screen and (max-width: $mobileS)
            bottom: 50px
        //    bottom: 100px

        li
            width: 25px
            height: 25px
            background-color: $white
            border-radius: 50%
            margin: 0 13px
            @media screen and (max-width: $tabletS)
                width: 16px
                height: 16px
                margin: 0 10px
            @media screen and (max-width: $mobileS)
                width: 17px
                height: 17px
                margin: 0 9px

            &.slick-active
                background-color: $red-purple

            & button
                //display: none
                opacity: 0
                width: 25px
                height: 25px

    &__arrow
        position: absolute
        top: 50%
        width: 47px
        height: 47px
        padding: 5px
        border-radius: 4px
        background-image: url("../img/arrow.svg")
        background-position: center
        background-color: $light-grey
        background-repeat: no-repeat
        opacity: 0.6
        z-index: 10
        @media screen and (max-width: $tabletS)
            width: 27px
            height: 27px
            background-size: 14px
            top: 40%

        &.arrow-prev
            left: 34px
            @media screen and (max-width: $tabletS)
                left: 20px

        &.arrow-next
            right: 34px
            transform: rotate(180deg)
            @media screen and (max-width: $tabletS)
                right: 20px

        &:hover
            opacity: 1
            cursor: pointer