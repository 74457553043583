@import "base"
.about
    position: relative
    background-image: url("../img/backgrounds/3.png")
    overflow-x: clip
    @media screen and (max-width: $mobileS)
        overflow-x: hidden

    .container
        position: relative
        padding: 68px 188px
        overflow: hidden
        @media screen and (max-width: $tabletS)
            padding: 59px 130px 86px
        @media screen and (max-width: $mobileS)
            padding: 40px 25px 63px

    h2
        margin: 0 auto 40px
        display: block
        width: fit-content
        border-bottom: 3px solid $black
        @media screen and (max-width: $tabletS)
            margin-bottom: 52px
        @media screen and (max-width: $mobileS)
            margin-bottom: 68px

    .img-left
        position: absolute
        left: 0
        bottom: -122px
        @media screen and (max-width: $tabletS)
            width: 220px
            bottom: -93px
        @media screen and (max-width: $mobileS)
            display: none

    .back-img-mob
        z-index: 0
        position: absolute
        top: 0
        right: 0
        opacity: 0.2
        background-image: url("../img/angel.png")
        background-size: 1083px
        background-repeat: no-repeat
        background-position: 0px -94px
        min-width: 50%
        height: 100%
        @media screen and (max-width: $tabletS)
            left: 0
            right: unset
            transform: scaleX(-1)
            background-size: 609px
            background-position: -17px -40px
        @media screen and (max-width: $tabletS)
            left: unset
            right: 0
            transform: unset
            background-size: 310px
            background-position: -65px 45px

    .back-img
        z-index: 0
        top: -94px
        left: 639px
        width: 1083px
        @media screen and (max-width: $tabletS)
            transform: scaleX(-1) rotate(16deg)
            width: 609px
            left: -173px
            top: -16px
        @media screen and (max-width: $mobileS)
            transform: none
            width: 310px
            top: 45px
            right: -97px
            left: unset

    .img-right
        display: none
        position: absolute
        z-index: 0
        @media screen and (max-width: $tabletS)
            opacity: 0.15
            display: block
            width: 563px
            right: -216px
            left: unset
            top: 229px
            transform: rotate(-4deg)
        @media screen and (max-width: $mobileS)
            display: none

    &__text
        margin-bottom: 30px
        font-family: "Open Sans"
        font-weight: 400
        font-size: 23px
        line-height: 26px
        letter-spacing: -0.02em
        text-align: center

        @media screen and (max-width: $tabletS)
            //margin-bottom: 30px
            font-size: 18px
            line-height: 20px
        @media screen and (max-width: $mobileS)
            margin-bottom: 15px
            font-size: 15px
            line-height: 17px


    &__design
        margin-top: 36px
        margin-left: 216px
        display: grid
        //align-items: center
        //justify-content: center
        column-gap: 45px
        @media screen and (max-width: $tabletS)
            margin-left: 0
        @media screen and (max-width: $mobileS)
            margin: 51px auto 0

        picture
            grid-column: 1/2
            grid-row: 1/3
            width: 415px
            height: auto

            img
                width: 100%
                height: auto

            @media screen and (max-width: $tabletS)
                grid-row: 2/3
                place-self: center
                margin-bottom: 66px
                width: 330px
            @media screen and (max-width: $mobileS)
                margin-bottom: 64px
                width: 90vw
                max-width: 90%

        .design
            //&__image
                grid-column: 1/2
                grid-row: 1/3
                width: 415px
                height: auto
                @media screen and (max-width: $tabletS)
                    grid-row: 2/3
                    place-self: center
                    margin-bottom: 66px
                    width: 330px
                @media screen and (max-width: $mobileS)
                    margin-bottom: 64px
                    width: 90vw
                    max-width: 90%

            &__text
                grid-column: 2/3
                grid-row: 1/2
                place-self: center
                font-family: "Thirsty Caps"
                font-size: 75px
                line-height: 67px
                text-align: center
                letter-spacing: 0.04em
                max-width: 359px
                @media screen and (max-width: $tabletS)
                    grid-column: 1/2
                    margin: 0
                    margin-bottom: 20px
                    font-size: 55px
                    line-height: 49px
                @media screen and (max-width: $mobileS)
                    margin-bottom: 38px


            &__btn
                grid-column: 2/3
                grid-row: 2/3
                place-self: start center
                padding: 7px 30px
                z-index: 10
                @media screen and (max-width: $tabletS)
                    grid-column: 1/2
                    grid-row: 3/4
                    place-self: center
                    font-size: 30px
                    line-height: 36px
                @media screen and (max-width: $mobileS)
                    font-size: 20px
                    line-height: 24px
