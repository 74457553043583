@import "base"

.why
    color: $white
    background-image: url("../img/backgrounds/1.jpg")

    .container
        padding: 82px 160px 1px
        @media screen and (max-width: $tabletS)
            padding: 35px 80px 57px
        @media screen and (max-width: $mobileS)
            padding: 70px 15px 35px


    &__phrase
        margin-left: 22px
        font-family: "Thirsty Caps"
        font-size: 90px
        line-height: 80px
        font-weight: 400
        text-align: center
        color: $yellow
        @media screen and (max-width: $tabletS)
            font-size: 55px
            line-height: 49px
        @media screen and (max-width: $mobileS)
            display: none

    &__phrase2
        margin-left: 22px
        margin-bottom: 20px
        font-family: "Thirsty Caps"
        font-size: 90px
        line-height: 80px
        font-weight: 400
        text-align: center
        color: $white

        @media screen and (max-width: $tabletS)
            font-size: 55px
            line-height: 49px
        @media screen and (max-width: $mobileS)
            display: none

    h2
        @media screen and (max-width: $tabletS)
            margin-bottom: 0px
        @media screen and (max-width: $mobileS)
            margin-bottom: 28px

    &__items
        display: flex
        gap: 4px
        margin-bottom: 74px
        @media screen and (max-width: $tabletS)
            flex-wrap: wrap
            justify-content: center
            gap: 0 20px
            margin-bottom: 15px

        .item
            display: flex
            flex-direction: column
            align-items: center
            padding-top: 35px
            width: 220px
            background-image: url("../img/icon-back.png")
            background-size: 176px
            background-position: top
            background-repeat: no-repeat
            @media screen and (max-width: $tabletS)
                width: 189px
                padding-top: 40px
                &:last-child
                    width: 200px
            @media screen and (max-width: $mobileS)
                width: 127px
                &:last-child
                    width: 127px

            &__icon
                margin-bottom: 47px
                height: 80px
                @media screen and (max-width: $tabletS)
                    height: 64px
                    width: 64px
                    margin-bottom: 29px
                    img
                        width: 100%
                        height: auto
                @media screen and (max-width: $mobileS)
                    width: 50px
                    height: 50px
                    margin-bottom: 35px


            &__title
                max-width: 205px
                margin-bottom: 8px
                font-weight: 700
                font-size: 20px
                line-height: 25px
                text-align: center
                @media screen and (max-width: $tabletS)
                    margin-bottom: 22px
                    font-size: 18px
                    line-height: 22px
                @media screen and (max-width: $mobileS)
                    margin-bottom: 8px
                    font-size: 13px
                    line-height: 16px


            &__desc
                font-weight: 600
                font-size: 13px
                line-height: 16px
                text-align: center
                @media screen and (max-width: $tabletS)
                    font-size: 12px
                    line-height: 15px
                @media screen and (max-width: $mobileS)
                    font-size: 8px
                    line-height: 12px
