@import "base"

.wrapper
    display: none

    &.active
        display: grid
        position: fixed
        top: 0
        left: 0
        width: 100vw
        height: 100vh
        overflow: hidden
        background-color: $black-op80
        z-index: 100

.popup
    place-self: center
    margin: auto

    width: max-content
    display: flex
    flex-direction: column
    align-items: center

    &-years
        padding: 130px 160px
        background-color: $black-op80
        opacity: 0.95
        color: $white
        @media screen and (max-width: $mobileS)
            padding: 64px 35px

    &-product
        background: white
        padding: 44px 70px 22px 70px
        position: relative
        display: grid
        grid-template-columns: 2fr 3fr
        width: 1000px
        max-width: 90vw
        gap: 65px 80px

        &__close
            cursor: pointer
            width: 42px
            height: 42px
            background: #D9D9D9
            display: grid
            place-items: center

            position: absolute
            top: 62px
            right: 85px
            z-index: 1000
            @media (max-width: $tabletS)
                right: 62px

            @media (max-width: $mobileS)
                display: none

            &-mob
                display: none
                cursor: pointer
                z-index: 1000

                @media (max-width: $mobileS)
                    display: block
                    position: absolute
                    top: 30px
                    left: 30px


        @media (max-width: $tabletS)
            gap: 26px 70px
            padding-top: 65px
        @media (max-width: $mobileS)
            padding: 50px 25px
            display: flex
            flex-direction: column
            gap: 17px

        &__after
            content: ''
            position: absolute
            right: 0
            height: 100%
            width: 100%
            // background-image: url('../img/angel.png')
            background-size: contain
            background-repeat: no-repeat
            background-position: right
            opacity: 0.05

        .products__characteristic
            font-size: 20px
            line-height: 24px

        &__base
            width: 335px
            @media (max-width: $tabletS)
                width: 230px
            @media (max-width: $mobileS)
                width: 200px

            grid-column: 1/2
            grid-row: 1/2

        &__info
            grid-column: 2/3
            grid-row: 1/2

        &__title
            font-size: 35px
            font-weight: 700
            line-height: 43px
            letter-spacing: 0em

            margin-top: 20px
            margin-bottom: 40px

            @media (max-width: $tabletS)
                font-size: 25px
                line-height: 31px
                margin-bottom: 30px

            @media (max-width: $mobileS)
                display: none

            &-mob
                display: none
                @media (max-width: $mobileS)
                    display: block
                    font-size: 25px
                    font-weight: 700
                    line-height: 31px
                    text-align: center
                    margin-bottom: 8px


        &__footer
            grid-row: 2/3
            grid-column: 1/3
            text-align: center

            @media (max-width: $tabletS)
                font-size: 14px
                line-height: 17px

            @media (max-width: $mobileS)
                margin-top: 13px
                font-size: 8px
                line-height: 11px
                letter-spacing: 0em
                font-family: "Open Sans"


        &__slider
            height: 335px
            @media (max-width: $tabletS)
                height: 230px
            @media (max-width: $mobileS)
                height: 200px

            img
                width: 100%

                width: 335px
                @media (max-width: $tabletS)
                    width: 230px
                @media (max-width: $mobileS)
                    width: 200px

        .popup-arrow

            position: absolute
            top: 50%
            width: 47px
            height: 47px
            padding: 5px
            border-radius: 4px
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='26' viewBox='0 0 20 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66948e-07 13L19.5 0.875645L19.5 25.1244L1.66948e-07 13Z' fill='%23D9D9D9'/%3E%3C/svg%3E")

            background-position: center
            background-repeat: no-repeat
            opacity: 0.6
            z-index: 10
            transition: .3s

            @media screen and (max-width: $tabletS)
                width: 25px
                height: 25px
                //background-size: 14px
                top: 45%
                box-sizing: content-box
                background-size: 100%

            @media screen and (max-width: $mobileS)
                width: 34px
                height: 34px
                padding: 5px
                border-radius: 4px
                background-image: url("../img/arrow.svg") !important
                background-size: 20px
                background-position: center
                background-color: $light-grey

            &.arrow-prev
                left: -60px
                @media screen and (max-width: $tabletS)
                    left: -50px

            &.arrow-next
                right: -60pxs
                transform: rotate(180deg)
                @media screen and (max-width: $tabletS)
                    right: -50px

            &:hover
                opacity: 1
                cursor: pointer

        .popup-arrow.arrow-next
            right: -60px

        .popup-arrow.arrow-prev
            left: -60px
        // background-image: url("data:image/svg+xml,%3Csvg width='20' height='26' viewBox='0 0 20 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66948e-07 13L19.5 0.875645L19.5 25.1244L1.66948e-07 13Z' fill='%23D9D9D9'/%3E%3C/svg%3E");


        .products__power
            margin: 10px 0

        .products__characteristic
            padding: 6px 0
            border-top: 1px solid black
            border-bottom: 1px solid black

            @media (max-width: $tabletS)
                font-size: 12px
                line-height: 14.5px


        &__text
            margin-bottom: 40px
            font-size: 20px
            line-height: 24px

            @media (max-width: $tabletS)
                font-size: 14px
                line-height: 19px
                margin-bottom: 40px

            @media (max-width: $mobileS)
                margin-bottom: 0
                font-size: 13px
                line-grid: 15.6px
                text-align: center

            p
                margin-bottom: 25px
                @media (max-width: $mobileS)
                    margin-bottom: 20px


        & .btn
            max-width: 245px
            font-size: 25px
            line-height: 36px
            @media (max-width: $tabletS)
                max-width: 160px
                font-size: 18px
                line-height: 22px
            @media (max-width: $mobileS)
                margin: 0 auto

    &__img
        height: 283px

        @media screen and (max-width: $tabletS)
            height: 125px
        @media screen and (max-width: $mobileS)
            height: 93px

        img
            height: 100%
            max-width: 100%

    &__title
        margin-bottom: 18px
        font-size: 40px
        line-height: 100%
        font-weight: 700
        @media screen and (max-width: $tabletS)
            font-size: 18px
        @media screen and (max-width: $mobileS)
            font-size: 15px


    &__subtitle
        margin-bottom: 25px
        font-size: 20px
        line-height: 100%
        font-weight: 500
        @media screen and (max-width: $tabletS)
            font-size: 12px
        @media screen and (max-width: $mobileS)
            font-size: 10px

    &__btns
        display: flex
        justify-content: center
        gap: 37px
        @media screen and (max-width: $mobileS)
            gap: 30px

        .btn
            font-size: 25px
            line-height: 30px
            font-weight: 600
            @media screen and (max-width: $mobileS)
                padding: 1px

            .content
                padding: 16px 32px
                @media screen and (max-width: $mobileS)
                    padding: 6px 9px

            @media screen and (max-width: $mobileS)
                font-size: 20px
                line-height: 24px
            @media screen and (max-width: $mobileS)
                font-size: 15px
                line-height: 18px