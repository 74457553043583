@import "base"
@keyframes info
    0%
        transform: scaleX(0)

    50%
        transform: translateX(56px)

    100%
        transform: translateX(56px)


.products
    background-image: url("../img/backgrounds/1.jpg")

    .container
        position: relative

    &__container
        background-size: cover

        &-first
            position: relative
            margin-bottom: 42px
            background-image: url("../img/backgrounds/4.png")
            overflow-x: clip
            @media screen and (max-width: $tabletS)
                margin-bottom: 56px

        &-second
            background-image: url("../img/backgrounds/5.png")

    .back-img
        width: 330px
        right: -69px
        bottom: -100px
        @media screen and (max-width: $tabletS)
            width: 277px
            right: -42px
            bottom: -87px
        @media screen and (max-width: $mobileS)
            display: none

    &__banner
        position: absolute
        left: 70px
        width: 377px
        transform: rotate(7deg)

        img
            width: 100%

        @media screen and (max-width: $tabletS)
            left: 30px
            width: 290px
        @media screen and (max-width: $mobileS)
            left: 9px
            width: 210px

        &-first
            top: -85px
            @media screen and (max-width: $tabletS)
                top: -72px
            @media screen and (max-width: $mobileS)
                top: -50px

        &-second
            top: -57px
            @media screen and (max-width: $tabletS)
                top: -65px
            @media screen and (max-width: $mobileS)
                top: -48px


    &__title
        margin-bottom: 3px
        font-family: "Open Sans"
        font-size: 30px
        line-height: 41px
        font-weight: 700

    &__description
        margin-bottom: 5px
        width: 219px

    &__power
        display: flex
        gap: 3px
        margin-bottom: 5px
        padding: 4px
        width: fit-content !important
        background-color: $grey
        border-radius: 4px

        .power-circle
            width: 13px
            height: 13px
            border-radius: 50%
            background-color: $black

    &__characteristic
        margin-bottom: 10px
        font-size: 13px
        line-height: 16px

        .characteristic
            &__name
                font-weight: 500

    &__btn
        font-size: 15px !important
        line-height: 18px !important

        &-mob
            display: none !important
            @media (max-width: $tabletS)
                font-size: 20px !important
                line-height: 24px !important
                display: block !important
                opacity: 0


    .slick-current .products__btn-mob, .slick-center .products__btn-mob
        @media (max-width: $tabletS)
            opacity: 1 !important
            

    .slick-center,
    .slick-current
        & .slide
            & .slide__image
                width: 320px
                border: none !important
                outline: none !important
                @media screen and (max-width: $tabletS)
                    width: 270px
                @media screen and (max-width: $mobileS)
                    width: 200px

                &:hover
                    cursor: pointer

        &:hover
            @media (min-width: $tabletS)
                .slide__info
                    margin-left: 56px
                    width: 219px
                    opacity: 1

    .slide
        position: relative
        display: flex
        justify-content: center
        align-items: flex-end
        height: 320px
        transition: 0.3s ease-in-out
        @media screen and (max-width: $tabletS)
            height: 315px
        //@media screen and (max-width: $mobileS)
            display: block
        &:focus-visible
            outline: none

        &.slick-center,
        &.slick-current
            & .slide__image
                width: 320px
                border: none !important
                outline: none !important
                @media screen and (max-width: $tabletS)
                    width: 270px
                @media screen and (max-width: $mobileS)
                    width: 200px

                &:hover
                    cursor: pointer

        &__content
            display: flex
            align-items: center
            @media (max-width: $tabletS)
                flex-direction: column
                gap: 45px


        &__image
            width: 250px
            height: auto
            transition: 0.3s ease-in-out
            filter: drop-shadow(7px 15px 15px rgba(0, 0, 0, 0.34))
            @media screen and (max-width: $tabletS)
                width: 200px
            @media screen and (max-width: $mobileS)
                width: 130px


        &__info
            margin-left: 0
            width: 0

            //transition: opacity 0.1s ease-in-out, margin 0.3s 0.2s ease-in-out, width 0.3s 0.2s ease-in-out
            transition: opacity 0.2s ease-in-out, margin 0.3s 0.2s ease-in-out, width 0.3s 0.2s ease-in-out

            overflow: hidden
            opacity: 0


            //transition: opacity 0.1s ease-in-out, margin 0.3s 0.2s ease-in-out, width 0.3s 0.2s ease-in-out



            @media (max-width: $tabletS)
                display: none

            & > *
                width: 219px

    .slick-current:hover
        //animation: 1s normal info ease

        //transition: margin 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.3s 0.3s ease-in-out
        //transition: opacity 0.1s ease-in-out, margin 0.3s 0.2s ease-in-out, width 0.3s 0.2s ease-in-out
        @media (min-width: $tabletS)
            .slide__info
                transition: margin 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.3s 0.3s ease-in-out

                margin-left: 56px
                width: 219px
                opacity: 1

    &__slider
        & .slick-track
            display: flex
            align-items: flex-end

        & .slick-list
            padding: 0 40px !important
            height: 320px
            @media screen and (max-width: $tabletS)
                padding: 0px 50px !important
            //height: 250px
            @media screen and (max-width: $mobileS)
                padding: 0 100px !important

            @media screen and (max-width: $tabletS)
                height: 315px

        &.slick-dotted.slick-slider
            padding-top: 19px
            padding-bottom: 37px
            @media screen and (max-width: $tabletS)
                padding: 5px 0 39px

    &__dots
        z-index: 10
        bottom: 8px

        @media (max-width: $tabletS)
            bottom: 99px

        li
            width: 20px
            height: 20px
            background-color: $grey
            border-radius: 50%
            @media screen and (max-width: $tabletS)
                width: 13px
                height: 13px

            &.slick-active
                background-color: $black

            & button
                //display: none
                opacity: 0
                width: 25px
                height: 25px

    &__arrow
        position: absolute
        top: 15%
        width: 300px
        height: 300px
        z-index: 10
        @media screen and (max-width: $tabletS)
            width: 200px
            height: 200px
            top: 25%

        &.arrow-prev
            left: 131px
            @media screen and (max-width: $tabletS)
                left: 50px

        &.arrow-next
            right: 131px
            @media screen and (max-width: $tabletS)
                right: 50px

        &:hover
            cursor: pointer