/* Экраны */
$mobileS: 600px
$tabletS: 1024px
$desktopS: 1440px

/* Цвета */
$white: rgba(255, 255, 255, 1)
$white-grey: #E3E3E3
$black: rgba(0, 0, 0, 1)
$black-op80: rgba(35, 35, 35, 0.95)
$black-op50: rgba(0, 0, 0, 0.5)
$light-grey: rgb(214, 212, 212)
$grey: #AFAFAF
$dark-grey: #676767
$yellow: #FAE420
$red-purple: #AA0250

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap')

@font-face
    font-family: "Spray Letters"
    src: url("./../fonts/SprayLetters.otf") format("opentype")
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: "Thirsty Caps"
    src: url("./../fonts/thirsty_caps/thirsty_caps/ThirstyCaps.otf") format("opentype")
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: "Inter"
    src: url("./../fonts/Inter/Inter-Regular.otf") format("opentype")
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Gilroy'
    src: url('./../fonts/Gilroy/Gilroy-Regular.eot')
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
    src: url('./../fonts/Gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    src: url('./../fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
    src: url('./../fonts/Gilroy/Gilroy-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal


@font-face
    font-family: 'Gilroy'
    src: url('./../fonts/Gilroy/Gilroy-Medium.eot')
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
    src: url('./../fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    src: url('./../fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
    src: url('./../fonts/Gilroy/Gilroy-Medium.ttf') format('truetype')
    font-weight: 500
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('./../fonts/Gilroy/Gilroy-SemiBold.eot')
    src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
    src: url('./../fonts/Gilroy/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    src: url('./../fonts/Gilroy/Gilroy-SemiBold.woff') format('woff'),
    src: url('./../fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype')
    font-weight: 600
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('./../fonts/Gilroy/Gilroy-Bold.eot')
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
    src: url('./../fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    src: url('./../fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
    src: url('./../fonts/Gilroy/Gilroy-Bold.ttf') format('truetype')
    font-weight: bold
    font-style: normal

@font-face
    font-family: 'Gilroy'
    src: url('./../fonts/Gilroy/Gilroy-ExtraBold.eot')
    src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
    src: url('./../fonts/Gilroy/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
    src: url('./../fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
    src: url('./../fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype')
    font-weight: 800
    font-style: normal

html
    overflow-x: hidden

*
    &:focus-visible
        outline: none
        border: none

body
    height: 100%
    margin: 0
    font-family: "Gilroy"
    font-weight: 400
    font-size: 18px
    line-height: 22px
    color: $black
    @media screen and (max-width: $tabletS)
        font-size: 16px
        line-height: 20px

.container
    width: 1440px
    margin: 0 auto
    max-width: 1440px
    @media screen and (max-width: $desktopS)
        width: 100vw

div
    box-sizing: border-box

h1, .h1
    font-family: "Spray Letters"
    font-size: 90px
    line-height: 104px
    font-weight: 400
    letter-spacing: 0.03em
    text-transform: uppercase
    color: $yellow
    @media screen and (max-width: $tabletS)
        font-size: 50px
        line-height: 58px
    @media screen and (max-width: $mobileS)
        font-size: 40px
        line-height: 46px

h2, .h2
    margin-top: 0
    margin-bottom: 40px
    font-family: "Thirsty Caps"
    font-size: 70px
    line-height: 62px
    font-weight: 400
    text-align: center
    letter-spacing: 0.03em
    @media screen and (max-width: $tabletS)
        margin-bottom: 52px
        font-size: 50px
        line-height: 44px

section
    background-size: cover
    background-position: center

button, .btn
    display: block
    box-sizing: border-box
    padding: 14px 30px
    border-radius: 8px
    font-family: "Inter"
    font-size: 30px
    line-height: 36px
    text-transform: uppercase
    transition: .3s ease-in-out
    text-align: center
    @media screen and (max-width: $tabletS)
        padding: 7px 20px
        font-size: 22px
        line-height: 28px

    &:focus-visible
        outline: none

    &:hover
        text-decoration: none
        cursor: pointer

    &-white
        background-color: $white
        color: $black

        &:hover
            background-color: $red-purple
            color: $white

    &-grey
        padding: 2px
        border: 0.5px solid transparent
        background-color: transparent
        color: $black
        border-radius: 6px

        .content
            width: 100%
            height: 100%
            background-color: $white-grey
            border-radius: 6px

        &:hover
            border: 0.5px solid $white-grey

    &-red
        background-color: $red-purple
        color: $white

        &:hover
            background-color: #F5C800 !important

            color: $black !important

    &-black
        background-color: $black
        color: $white

        &:hover
            background-color: $white
            color: $black

p
    margin: 0

a
    color: $black
    text-decoration: none

    &:hover
        text-decoration: underline
        cursor: pointer

.yellow
    color: $yellow

.f-gilroy
    font-family: 'Gilroy'

.fs-25
    font-size: 25px
    line-height: 31px
    @media screen and (max-width: $tabletS)
        font-size: 16px
        line-height: 20px
    @media screen and (max-width: $mobileS)
        font-size: 13px
        line-height: 16px


.fs-20
    font-size: 20px
    @media screen and (max-width: $tabletS)
        font-size: 18px
    @media screen and (max-width: $mobileS)
        font-size: 14px

.fs-23
    font-size: 23px
    @media screen and (max-width: $tabletS)
        font-size: 18px
    @media screen and (max-width: $mobileS)
        font-size: 14px

.lh-20
    line-height: 20px

.lh-26
    line-height: 26px
    @media screen and (max-width: $tabletS)
        line-height: 20px
    @media screen and (max-width: $mobileS)
        line-height: 14px

.fw-400
    font-weight: 400

.fw-500
    font-weight: 500

.fw-600
    font-weight: 600

.back-img
    position: absolute
    opacity: 0.15

.slick-dotted.slick-slider
    margin-bottom: 0
